<template>
  <div id="addUser" class="p-3 usermanagement-form">
    <app-title-content></app-title-content>
    <div class="mt-5">
      <div class="d-flex headline-list">
        <div class="py-2">
          <span> ส่วนที่ 1 : </span>
          <span class="weight-700">{{ titleName }}</span>
        </div>
      </div>
    </div>

    <div class="blue-box mt-3">
      <div class="header">ข้อมูลผู้ใช้งาน</div>
      <div class="content">
        <div class="row mb-3">
          <div class="col-12 col-lg-6">
            <div class="d-flex align-items-center">
              <div class="mr-3 label-min-width">
                Username <span class="text-danger">*</span> :
              </div>
              <div class="flex-fill">
                <input
                  type="text"
                  class="form-control input-custom"
                  :disabled="mode === 'edit'"
                  :class="{
                    'input--error':
                      submit && $v.form.username.$invalid && mode === 'add',
                  }"
                  v-model="form.username"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 align-self-center">
            <span
              v-if="
                submit &&
                !($v.form.username.required || $v.form.username.canUse) &&
                !$v.form.username.$pending &&
                mode === 'add'
              "
              class="text--error"
            >
              <font-awesome-icon :icon="['fas', 'times-circle']" /> Username
              นี้ถูกใช้งานแล้ว
            </span>
            <span
              v-if="
                submit &&
                $v.form.username.required &&
                $v.form.username.canUse &&
                !$v.form.username.$pending &&
                mode === 'add'
              "
              class="text-success"
            >
              <font-awesome-icon :icon="['fas', 'check-circle']" />
              สามารถใช้งาน Username นี้ได้
            </span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 col-lg-6">
            <div class="d-flex align-items-center">
              <div class="mr-3 label-min-width">
                ชื่อ <span class="text-danger">*</span> :
              </div>
              <div class="flex-fill">
                <input
                  type="text"
                  class="form-control input-custom"
                  v-model="form.firstname"
                  :class="{
                    'input--error': submit && $v.form.firstname.$invalid,
                  }"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6"></div>
        </div>
        <div class="row mb-3">
          <div class="col-12 col-lg-6">
            <div class="d-flex align-items-center">
              <div class="mr-3 label-min-width">
                นามสกุล <span class="text-danger">*</span> :
              </div>
              <div class="flex-fill">
                <input
                  type="text"
                  class="form-control input-custom"
                  v-model="form.lastname"
                  :class="{
                    'input--error': submit && $v.form.lastname.$invalid,
                  }"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6"></div>
        </div>
        <div class="row mb-3">
          <div class="col-12 col-lg-6">
            <div class="d-flex align-items-center">
              <div class="mr-3 label-min-width">
                อีเมล <span class="text-danger">*</span> :
              </div>
              <div class="flex-fill">
                <input
                  type="text"
                  class="form-control input-custom"
                  :class="{
                    'input--error':
                      submit &&
                      $v.form.email.$invalid &&
                      !$v.form.email.$pending,
                  }"
                  v-model="form.email"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 align-self-center">
            <span
              v-if="submit && !$v.form.email.canUse && !$v.form.email.$pending"
              class="text--error"
            >
              <font-awesome-icon :icon="['fas', 'times-circle']" />
              อีเมลนี้ถูกใช้งานแล้ว
            </span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 col-lg-6">
            <div class="d-flex align-items-center">
              <div class="mr-3 label-min-width">เบอร์โทรติดต่อ :</div>
              <div class="flex-fill">
                <input
                  type="text"
                  class="form-control input-custom"
                  v-model="form.phoneNumber"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6"></div>
        </div>
        <div class="row mb-3">
          <div class="col-12 col-lg-6">
            <div class="d-flex align-items-center">
              <div class="mr-3 label-min-width">
                สังกัดในกรมทรัพยากรน้ำ <span class="text-danger">*</span> :
              </div>
              <div class="flex-fill">
                <select2
                  class="select-custom"
                  :options="departmentList"
                  v-model="form.departmentSelected"
                  :settings="{
                    language: {
                      noResults: function () {
                        return 'ไม่พบข้อมูล';
                      },
                    },
                  }"
                  :class="{
                    'input--error':
                      submit && $v.form.departmentSelected.$invalid,
                  }"
                >
                </select2>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6"></div>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <div class="d-flex headline-list">
        <div class="py-2">
          <span> ส่วนที่ 2 : </span>
          <span class="weight-700">ตั้งค่ารหัสผ่าน</span>
        </div>
      </div>
    </div>
    <div class="blue-box mt-3">
      <div class="header">ตั้งค่ารหัสผ่าน</div>
      <div class="content">
        <template v-if="mode === 'add'">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="d-flex align-items-baseline mb-3">
                <div class="mr-2">
                  รหัสผ่าน <span class="text-danger">*</span> :
                </div>
                <div class="flex-fill">
                  <div>
                    <input
                      type="text"
                      class="form-control input-custom"
                      :class="{
                        'input--error': submit && $v.form.password.$invalid,
                      }"
                      v-model="form.password"
                    />
                  </div>
                  <div>
                    <small
                      class="font-navyblue"
                      :class="[
                        {
                          'text--error': submit && $v.form.password.$invalid,
                        },
                      ]"
                    >
                      *รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร ประกอบด้วย a-z, A-Z,
                      0-9 และอักขระพิเศษ
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="d-flex align-items-center mb-3">
                <div class="mr-2">
                  ยืนยันรหัสผ่าน <span class="text-danger">*</span> :
                </div>
                <div class="flex-fill">
                  <input
                    type="text"
                    class="form-control input-custom"
                    :class="{
                      'input--error':
                        submit && $v.form.confirmpassword.$invalid,
                    }"
                    v-model="form.confirmpassword"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="row">
            <div class="col-12">
              <button class="btn btn-save" @click="changePassword">
                ตั้งค่ารหัสผ่านใหม่
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="mt-5">
      <div class="d-flex w-100 justify-content-center">
        <router-link
          :to="{ name: 'UserManagement' }"
          class="btn btn-outline-save"
          >ยกเลิก</router-link
        >
        <div class="mx-5"></div>
        <button type="submit" class="btn btn-save" @click="submitForm">
          บันทึก
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, sameAs, email } from "vuelidate/lib/validators";
import { FilterService, UserService } from "@/services/main.service.js";

export default {
  name: "add-user",
  data() {
    return {
      isLoading: false,
      submit: false,
      mode: null,
      departmentList: [],
      formDefault: {
        username: null,
        firstname: null,
        lastname: null,
        email: null,
        phoneNumber: null,
        departmentSelected: null,
        password: null,
        confirmpassword: null,
        roleId: 1,
      },
      form: {
        username: null,
        firstname: null,
        lastname: null,
        email: null,
        phoneNumber: null,
        departmentSelected: null,
        password: null,
        confirmpassword: null,
        roleId: 1,
      },
    };
  },
  validations: {
    form: {
      username: {
        required,
        canUse(value) {
          if (value === "") return true;

          return new Promise(async (resolve, reject) => {
            if (this.mode === "add") {
              const isPass = await this.checkUsername(value);
              resolve(isPass);
            } else {
              resolve(true);
            }
          });
        },
      },
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      departmentSelected: {
        required,
      },
      email: {
        required,
        email,
        canUse(value) {
          if (value === "") return true;

          return new Promise(async (resolve, reject) => {
            if (this.mode === "add") {
              const isPass = await this.checkEmailDuplicate(value);
              resolve(isPass);
            } else {
              const isPass = await this.checkEmailDuplicateEdit(value);
              resolve(isPass);
            }
          });
        },
      },
      password: {
        required,
        validator(value) {
          if (
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^\&*\)\(+=._-])[a-zA-Z\d[!@#\$%\^\&*\])\(+=._-]{8,20}$/.test(
              value
            )
          ) {
            return true;
          } else {
            return false;
          }
        },
      },
      confirmpassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
    validationGroupAdd: [
      "form.username",
      "form.firstname",
      "form.lastname",
      "form.email",
      "form.password",
      "form.confirmpassword",
    ],
    validationGroupEdit: [
      "form.username",
      "form.firstname",
      "form.lastname",
      "form.email",
    ],
  },
  async created() {
    this.mode = this.$route.params?.mode;
    this.userId = this.$route.params?.userId;

    await this.getDepartment();

    if (this.mode === "edit") {
      this.getUserDetail();
    }
  },
  methods: {
    async getDepartment() {
      this.departmentList = await FilterService.getFilterDepartment().then(
        (res) => {
          return res;
        }
      );
    },
    async getUserDetail() {
      await UserService.getProfile(this.userId)
        .then((res) => {
          this.form = {
            ...this.formDefault,
            username: res.userName,
            firstname: res.firstName,
            lastname: res.lastName,
            email: res.email,
            phoneNumber: res.phoneNumber,
            departmentSelected: res.departmentId,
          };
        })
        .catch((err) => {
          this.form = this.formDefault;
        });
    },
    async submitForm() {
      this.submit = true;

      if (this.mode === "add" && this.$v.validationGroupAdd.$invalid) {
        return;
      } else if (this.mode === "edit" && this.$v.validationGroupEdit.$invalid) {
        return;
      }

      const param = {
        userName: this.form.username,
        firstName: this.form.firstname,
        lastName: this.form.lastname,
        email: this.form.email,
        phoneNumber: this.form.phoneNumber,
        departmentId: this.form.departmentSelected,
        roleId: this.form.roleId,
      };

      this.isLoading = true;
      if (this.mode === "add") {
        param["password"] = this.form.password;

        await UserService.postAddProfile(param)
          .then((res) => {
            this.isLoading = false;
            if (res.status) {
              this.alertSuccess("เพิ่มข้อมูลสำเร็จ").then((res2) => {
                this.$router.push({
                  name: "UserManagement",
                });
              });
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.alertFail("ไม่สามารถเพิ่มข้อมูลได้");
          });
      } else if (this.mode === "edit") {
        param["userId"] = this.userId;
        await UserService.postUpdateProfile(param)
          .then((res) => {
            this.isLoading = false;
            if (res.status) {
              this.alertSuccess("แก้ไขข้อมูลสำเร็จ").then((res2) => {
                this.$router.push({
                  name: "UserManagement",
                });
              });
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.alertFail("ไม่สามารถแก้ไขข้อมูลได้");
          });
      }
    },
    async checkUsername(username) {
      return await UserService.getIsExistUserName(username)
        .then((res) => {
          if (res && res?.status) {
            return true;
          } else {
            return false;
          }
        })
        .catch((err) => {
          return false;
        });
    },
    async checkEmailDuplicate(email) {
      return await UserService.getIsExistEmail(email)
        .then((res) => {
          if (res && res?.status) {
            return true;
          } else {
            return false;
          }
        })
        .catch((err) => {
          return false;
        });
    },
    async checkEmailDuplicateEdit(email) {
      const userId = this.userId;
      return await UserService.getCheckEmail(email, userId)
        .then((res) => {
          if (res && res?.status) {
            return true;
          } else {
            return false;
          }
        })
        .catch((err) => {
          return false;
        });
    },
    async changePassword() {
      await this.$swal
        .fire({
          html: `<div class="d-col">
                  <div class="mb-3 fontsize-h3">ตั้งค่ารหัสผ่านใหม่</div>
                  <div class="row">
                    <div class="col-12 mb-3 d-inline-flex">
                    <div class="align-self-center flex-shrink-0 mr-3">รหัสผ่านปัจจุบัน : </div>
                      <input type="text" id="swal-input1" class="form-control">
                    </div>
                    <div class="col-12 mb-3 d-inline-flex">
                    <div class="align-self-center flex-shrink-0 mr-3">รหัสผ่านใหม่ : </div>
                      <input type="text" id="swal-input2" class="form-control">
                    </div>
                  </div>
                </div>`,
          showCancelButton: true,
          confirmButtonText: "ตกลง",
          cancelButtonText: "ยกเลิก",
          customClass: {
            container: "alert-default action-btn-around",
            confirmButton: "btn btn-save",
            cancelButton: "btn btn-outline-save",
          },
          buttonsStyling: false,
          reverseButtons: true,
          preConfirm: async () => {
            return new Promise(function (resolve, reject) {
              const curPass = document.getElementById("swal-input1").value;
              const newPass = document.getElementById("swal-input2").value;

              if (!curPass && !newPass) {
                throw new Error("");
              } else {
                resolve([curPass, newPass]);
              }
            }).catch((error) => {
              const curPass = document.getElementById("swal-input1");
              const newPass = document.getElementById("swal-input2");

              if (!curPass.value) curPass.classList.add("input--error");

              if (!newPass.value) newPass.classList.add("input--error");

              this.$swal.showValidationMessage("กรุณากรอกข้อมูลให้ครบ");
            });
          },
        })
        .then(async (result) => {
          if (result.isConfirmed && result.value.length === 2) {
            await this.callChangePassword(result.value[0], result.value[1]);
          }
        });
    },
    async callChangePassword(curPass, newPass) {
      const param = {
        UserId: this.userId,
        CurrentPassword: curPass,
        NewPassword: newPass,
      };

      await UserService.postChangePassword(param)
        .then((res) => {
          if (res) {
            this.alertSuccess("ตั้งค่ารหัสผ่านใหม่สำเร็จ");
          }
        })
        .catch((err) => {
          this.alertFail("ตั้งค่ารหัสผ่านใหม่ไม่สำเร็จ");
        });
    },
  },
  computed: {
    titleName() {
      return this.mode === "edit" ? "แก้ไขผู้ใช้งาน" : "เพิ่มผู้ใช้งาน";
    },
  },
};
</script>

<style lang="scss" scoped>
.usermanagement-form {
  .label-min-width {
    width: 200px;
    text-align: right;
  }
}
</style>
